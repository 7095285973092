import React, { useEffect, useState } from 'react';
import './section-hero.styles.scss';

function SectionHero({ sectionHeroData }) {
  const [hideArrow, setHideArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setHideArrow(true);
      } else {
        setHideArrow(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className="section-hero" aria-label="Hero Section">
      {/* <img src={img.src} alt={img.alt} fetchpriority="high"></img> */}
      <div className="grid headline">
        <div className="cols">
          <h1>Andreas Glosner</h1>
          <h2>Developer</h2>
        </div>
      </div>
      {!hideArrow && (
        <div className="scroll-arrow">
          <span className="arrow fa fa-arrow-down"></span>
        </div>
      )}
    </section>
  );
}

export default SectionHero;
