import React, { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';

import './section-intro.styles.scss';

gsap.registerPlugin(ScrollTrigger);

function SectionIntro({ sectionIntroData }) {

  const { headline, webdev, gamedev } = sectionIntroData;

  const { t } = useTranslation();

  const comp = useRef();
  const webdevRef = useRef(null);
  const gamedevRef = useRef(null);

  useLayoutEffect(() => {

    let mm = gsap.matchMedia();

    mm.add("(min-width: 992px)", () => {

      gsap.from(webdevRef.current, {
        duration: 0.8,
        opacity: 0,
        delay: 0.2,
        ease: "power3.in",
        scrollTrigger: {
          trigger: comp.current,
          start: 'top 80%',
          toggleActions: 'play none none none',
        },
      });

      gsap.from(gamedevRef.current, {
        duration: 0.8,
        opacity: 0,
        delay: 0.2,
        ease: "power3.in",
        scrollTrigger: {
          trigger: comp.current,
          start: 'top 80%',
          toggleActions: 'play none none none',
        },
      });

    }, comp);

    return () => mm.revert();

  }, []);

  return (
    <section className="grid section-intro comp-spacing" aria-label="Vorstellung" ref={comp}>
      <h3 className="cols headline">{t(headline)}</h3>

      <div className="cols">
        <div className="content-webdev" ref={webdevRef}>
          <div className="textblock">
            <h2 className="headline">{t(webdev.headline)}</h2>
            <p className="title">{t(webdev.title)}</p>
            <p className="copy">{t(webdev.copy)}</p>
          </div>
        </div>

        <div className="content-gamedev" ref={gamedevRef}>
          <div className="textblock">
            <h2 className="headline">{t(gamedev.headline)}</h2>
            <p className="title">{t(gamedev.title)}</p>
            <p className="copy">{t(gamedev.copy)}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionIntro;
