import { useTranslation } from 'react-i18next';
import './main-nav.styles.scss';

function Mainnav() {
  const { i18n } = useTranslation();

  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  return (
    <nav className="grid main-nav" aria-label="Navigation">
      <div className="cols">
        <div className="mainnav-wrapper">
          <div className="content">
            <button onClick={() => handleChangeLng("de")} className={i18n.language === "de" ? "active" : ""}>DE</button>
            <span>|</span>
            <button onClick={() => handleChangeLng("en")} className={i18n.language === "en" ? "active" : ""}>EN</button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Mainnav;
